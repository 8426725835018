import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import { Row, Col } from "antd"

import { LogoColor } from "components/Logo"
import { isUserSignedIn } from "utilities/auth"
import { Store } from "store"

import styles from "./Authentication.module.scss"
import LoginForm from "./LoginForm"

const propTypes = {
    // React router
    location: PropTypes.shape({
        state: PropTypes.object,
    }).isRequired,
}
const defaultProps = {}

const Authentication = () => {
    const { state } = useContext(Store)

    const {
        app: { user },
    } = state

    if (isUserSignedIn(user)) {
        return <Redirect to="/" />
    }

    return (
        <div className={styles.background}>
            <Row type="flex" justify="center">
                <Col
                    xl={10}
                    xxl={8}
                    style={{ marginTop: "3%", marginBottom: "3%" }}
                >
                    <div className={styles.form}>
                        <div className={styles.logo}>
                            <LogoColor />
                        </div>

                        <LoginForm />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

Authentication.propTypes = propTypes
Authentication.defaultProps = defaultProps

export default Authentication
