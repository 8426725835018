import React, { useContext } from "react"
import get from "lodash/get"
import isUndefined from "lodash/isUndefined"
import { Skeleton } from "antd"
import ReactRouterPropTypes from "react-router-prop-types"
import { Breadcrumb } from "components/Breadcrumb"
import { Helmet } from "react-helmet"

import { Store } from "store"
import { fetchNewFeature } from "actions"
import {
    selectSEOTitle,
    selectSEODescription,
    selectSEOImageURL,
} from "selectors"
import { PageTitle } from "components/PageTitle"
import { SEO } from "components/SEO"
import { Main } from "components/Main"
import { formatDate } from "utilities/formatters"
import { bodyToReactComponents } from "utilities/contentful.js"

import styles from "./NewFeature.module.scss"

const propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
}
const defaultProps = {}

const NewFeature = ({ match }) => {
    const { state, dispatch } = useContext(Store)

    const featureId = get(match, ["params", "id"])

    const user = get(state, ["app", "user"])

    React.useEffect(() => {
        fetchNewFeature(featureId, user, dispatch)
    }, [dispatch, featureId, user])

    const loading = get(state, ["app", "loading"])
    if (loading) {
        return <Skeleton active />
    }

    const newFeature = get(state, ["newFeatures", "current"])
    const name = get(newFeature, ["fields", "name"])
    const releaseDate = get(newFeature, ["fields", "releaseDate"])
    const body = get(newFeature, ["fields", "copy", "fields", "body"])

    const seoTitle = selectSEOTitle(newFeature)
    const seoDescription = selectSEODescription(newFeature)
    const seoImage = selectSEOImageURL(newFeature)

    return (
        <>
            <Helmet>
                <body className="new-feature-page" />
            </Helmet>
            <Main
                title="New Features from Downstream"
                isNotFound={isUndefined(name) && isUndefined(releaseDate)}
            >
                <SEO
                    title={seoTitle}
                    description={seoDescription}
                    image={seoImage}
                    article
                />
                <article className={styles["feature-content"]}>
                    <Breadcrumb />
                    <PageTitle>{name}</PageTitle>
                    <p
                        className={styles.description}
                    >{`Released on ${formatDate(releaseDate)}`}</p>
                    {bodyToReactComponents(body)}
                </article>
            </Main>
        </>
    )
}

NewFeature.propTypes = propTypes
NewFeature.defaultProps = defaultProps

export default NewFeature
