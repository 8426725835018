import get from "lodash/get"
import has from "lodash/has"
import set from "lodash/fp/set"
import { createClient } from "contentful"

import { getUTCDate } from "utilities/dates"

const buildClientConfig = stage => {
    switch (stage) {
        case "Production":
            return {
                accessToken:
                    process.env
                        .REACT_APP_CONTENTFUL_CONTENT_DELIVERY_API_TOKEN_PROD,
                host: "cdn.contentful.com",
            }
        case "Beta":
            return {
                accessToken:
                    process.env
                        .REACT_APP_CONTENTFUL_CONTENT_DELIVERY_API_TOKEN_BETA,
                host: "preview.contentful.com",
            }
        default:
            throw new Error("You must set up your .env variables")
    }
}

const contentfulClient = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    ...buildClientConfig(process.env.REACT_APP_CONTENTFUL_STAGE),
})

export const getSEOEntry = async id => {
    const { fields } = await contentfulClient.getEntry(id)
    return fields
}

export const getCategory = async (
    categoryPath,
    includePrivateEntries = false
) => {
    const categoryList = await contentfulClient.getEntries({
        "fields.path": categoryPath,
        content_type: "category",
        include: 2, // include Topic Links
        ...(includePrivateEntries ? {} : { "fields.private[ne]": true }),
    })
    // filter topics without fields
    const category = get(categoryList, ["items", 0])
    const topicsFiltered = get(category, ["fields", "topics"], []).filter(
        topic => has(topic, ["fields"])
    )
    return set(["fields", "topics"], topicsFiltered, category)
}

export const getCategories = async (includePrivateEntries = false) => {
    const { items } = await contentfulClient.getEntries({
        content_type: "category",
        ...(includePrivateEntries ? {} : { "fields.private[ne]": true }),
    })
    return items
}

export const getNewFeature = async (
    featurePath,
    includePrivateEntries = false
) => {
    const newFeaturesList = await contentfulClient.getEntries({
        "fields.path": featurePath,
        content_type: "newFeature",
        include: 2, // include Links,
        ...(includePrivateEntries ? {} : { "fields.private[ne]": true }),
    })
    return get(newFeaturesList, ["items", 0])
}

export const getNewFeatures = async (
    { skip = 0 },
    includePrivateEntries = false
) => {
    const { items: data, total } = await contentfulClient.getEntries({
        content_type: "newFeature",
        order: "-fields.releaseDate",
        limit: 5, // page size
        skip,
        ...(includePrivateEntries ? {} : { "fields.private[ne]": true }),
    })
    return { data, total }
}

export const getRecentlyAddedFeatureCount = async (
    includePrivateEntries = false
) => {
    const { total } = await contentfulClient.getEntries({
        content_type: "newFeature",
        "fields.releaseDate[gte]": getUTCDate(14).toISOString(),
        ...(includePrivateEntries ? {} : { "fields.private[ne]": true }),
    })
    return total
}
