import React, { useEffect } from "react"
import get from "lodash/get"
import isUndefined from "lodash/isUndefined"
import { Skeleton } from "antd"
import ReactRouterPropTypes from "react-router-prop-types"
import scrollTo from "antd/lib/_util/scrollTo"

import { Store } from "store"
import { fetchCategory } from "actions"
import {
    selectSEOTitle,
    selectSEODescription,
    selectSEOImageURL,
} from "selectors"
import { Main } from "components/Main"
import { SEO } from "components/SEO"
import { PageNavigation } from "components/PageNavigation"

import { Topics } from "./Topics"

import styles from "./Category.module.scss"

const propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
}
const defaultProps = {}

const Category = ({ match }) => {
    const { state, dispatch } = React.useContext(Store)

    const categoryId = get(match, ["params", "id"])

    const user = get(state, ["app", "user"])

    // scroll to element id using hash on page mount
    useEffect(() => {
        const scrollToAnchor = () => {
            const hash = window.location.hash
            if (hash) {
                const element = document.querySelector(hash)
                if (element) {
                    const { top } = element.getBoundingClientRect()
                    scrollTo(top)
                }
            }
        }
        // gives the page time to load, so the y coordinate considers images
        setTimeout(() => scrollToAnchor(), 800)
    }, [])

    useEffect(() => {
        fetchCategory(categoryId, user, dispatch)
    }, [categoryId, dispatch, user])

    const loading = get(state, ["app", "loading"])
    if (loading) {
        return <Skeleton />
    }

    const currentCategory = get(state, ["categories", "current"])
    const categoryName = get(currentCategory, ["fields", "name"])
    const description = get(currentCategory, ["fields", "description"])
    const topics = get(currentCategory, ["fields", "topics"], [])
    const navItems = topics.map(({ fields }) => ({
        href: `#${fields.path}`,
        title: fields.name,
    }))

    const seoTitle = selectSEOTitle(currentCategory)
    const seoDescription = selectSEODescription(currentCategory)
    const seoImage = selectSEOImageURL(currentCategory)

    return (
        <Main
            subTitle={categoryName}
            isNotFound={isUndefined(categoryName) && isUndefined(description)}
        >
            <SEO
                title={seoTitle}
                description={seoDescription}
                image={seoImage}
                article
            />
            <PageNavigation title={categoryName} items={navItems} />
            <article className={styles["category-article"]}>
                <div className={styles["category-header"]}>
                    <h1>{categoryName}</h1>
                </div>
                <Topics topics={topics} />
            </article>
        </Main>
    )
}

Category.propTypes = propTypes
Category.defaultProps = defaultProps

export default Category
