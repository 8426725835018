import React from "react"
import PropTypes from "prop-types"

import Topic from "./Topic"

const propTypes = {
    topics: PropTypes.arrayOf(PropTypes.shape()),
}
const defaultProps = {
    topics: [],
}

const Topics = ({ topics }) => {
    return topics.map(({ fields, sys }) => (
        <Topic field={fields} key={sys.id} />
    ))
}

Topics.propTypes = propTypes
Topics.defaultProps = defaultProps

export default Topics
