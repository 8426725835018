import React from "react"
import Media from "react-media"
import PropTypes from "prop-types"
import { Anchor, BackTop } from "antd"

import { MobilePageNavigation } from "components/MobilePageNavigation"
import { mediaQueries, offsetTop } from "theme"
import { Breadcrumb } from "components/Breadcrumb"

import styles from "./PageNavigation.module.scss"

const propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
            active: PropTypes.bool,
        })
    ),
    disableMobileMenu: PropTypes.bool,
}
const defaultProps = {
    title: null,
    items: [],
    disableMobileMenu: false,
}

const PageNavigation = ({ title, items, disableMobileMenu }) => {
    return (
        <Media queries={mediaQueries}>
            {({ small }) =>
                small ? (
                    !disableMobileMenu && (
                        <MobilePageNavigation title={title} items={items} />
                    )
                ) : (
                    <nav className={styles["content-nav"]}>
                        <BackTop />
                        <Anchor
                            className={styles["anchor"]}
                            offsetTop={offsetTop}
                            targetOffset={0}
                        >
                            <Breadcrumb />
                            {title && (
                                <h2 className={styles["nav-title"]}>{title}</h2>
                            )}
                            {items &&
                                items.map(({ href, title }, idx) => (
                                    <Anchor.Link
                                        key={idx}
                                        href={href}
                                        title={title}
                                    />
                                ))}
                        </Anchor>
                    </nav>
                )
            }
        </Media>
    )
}

PageNavigation.propTypes = propTypes
PageNavigation.defaultProps = defaultProps

export default PageNavigation
