import React, { useContext } from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import { Helmet } from "react-helmet"
import classNames from "classnames"

import { Store } from "store"
import { bodyToReactComponents } from "utilities/contentful.js"
import { isUserSignedIn } from "utilities/auth"

import styles from "./Topics.module.scss"

import { offsetTop } from "theme"

const propTypes = {
    field: PropTypes.shape({
        copy: PropTypes.objectOf(PropTypes.any),
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        path: PropTypes.string,
    }).isRequired,
}
const defaultProps = {}

const Topic = ({ field: { copies = [], name, path } }) => {
    const { state } = useContext(Store)

    const {
        app: { user },
    } = state

    const renderBodyContent = (body, topicType, key) => (
        <div
            key={key}
            className={classNames({
                [styles.recommendation]: topicType === "recommendation",
                [styles.warning]: topicType === "warning",
            })}
        >
            {body === null
                ? "Content is coming soon!"
                : bodyToReactComponents(body)}
        </div>
    )

    const renderTableContent = (table, key) => {
        if (!table.tableData || !table.tableData.length) {
            return null
        }

        const headerRow=table.tableData[0]
        const rows=table.tableData.slice(1)

        return (<table key={key}>
            <thead>
                <tr>
                    {headerRow.map((col, hIdx) => {
                        return (<th key={hIdx}>
                            {col}
                        </th>)
                    })}
                </tr>
            </thead>
            {rows.map((row, rIdx) => {
                return (<tr key={rIdx}>
                    {row.map((col, tdIdx) => {
                        return (<td key={tdIdx}>
                            {col}
                        </td>)
                    })}
                </tr>)
            })}
        </table>)
    }

    const renderIframeContent = (iframe, key) => {
        if (!iframe) {
            return null
        }

        return (
            <div className={styles["embedded-iframe-container"]}>
                <div className={styles["embedded-iframe-wrapper"]} key={key} dangerouslySetInnerHTML={{__html: iframe} } />
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <body className="topic-page" />
            </Helmet>
            <div>
                <div
                    id={path}
                    style={{
                        paddingTop: offsetTop,
                        marginTop: -offsetTop,
                    }}
                >
                    <h2 className={styles["topic-name"]}>{name}</h2>
                </div>
                <div className={styles["topic-content"]}>
                    {copies.map((copy, idx) => {
                        const body = get(copy, ["fields", "body"], null)
                        const isPrivate = get(
                            copy,
                            ["fields", "private"],
                            false
                        )
                        let tableContent = null
                        const table = get(copy, ["fields", "table"], null)
                        if (table) {
                            tableContent = renderTableContent(table, idx)
                        }
                        let iframeContent = null
                        const iframe = get(copy, ["fields", "iframe"], null)
                        if (iframe) {
                            iframeContent = renderIframeContent(iframe, idx)
                        }
                        const topicType = get(copy, ["fields", "type"], null)
                        if (body) {
                            if (
                                !isPrivate ||
                                (isPrivate && isUserSignedIn(user))
                            ) {
                                return (
                                    <>
                                        {renderBodyContent(body, topicType, idx)}
                                        {iframeContent}
                                        {tableContent}
                                    </>
                                )
                            }
                        }
                        return (
                            <>
                                {iframeContent}
                                {tableContent}
                            </>
                        )
                    })}
                </div>

                <hr className="ds-divider" />
            </div>
        </>
    )
}

Topic.propTypes = propTypes
Topic.defaultProps = defaultProps

export default Topic
