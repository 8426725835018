import React from "react"
import { Link } from "react-router-dom"
import Media from "react-media"
import classNames from "classnames"
import PropTypes from "prop-types"

import { Header } from "components/Header"
import { mediaQueries } from "theme"
import { Logo } from "components/Logo"

import styles from "./Main.module.scss"

const propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    isNotFound: PropTypes.bool,
}
const defaultProps = {
    title: "Downstream Help Center",
    subTitle: null,
    showNotFound: false,
}

const Main = ({ title, subTitle, isNotFound, children }) => {
    return (
        <Media queries={mediaQueries}>
            {({ small }) => (
                <>
                    <Header title={title} subTitle={subTitle} />
                    {isNotFound ? (
                        <div className={styles["centered-card"]}>
                            <h1 className={styles.header}>Page not found.</h1>
                            <p>
                                The page you were looking for doesn&apos;t exist
                                or contains protected content.{" "}
                                <Link to="/auth">Sign in</Link> to access
                                protected content.
                            </p>
                        </div>
                    ) : (
                        <main
                            className={classNames("container", "d-flex", {
                                "flex-column": small,
                            })}
                        >
                            {children}
                        </main>
                    )}
                    <footer
                        className={classNames(
                            styles["help-footer"],
                            "container"
                        )}
                    >
                        <div className={styles["footer-main"]}>
                            <Logo
                                fills={{
                                    large: "#292A33",
                                    small: "#0097FF",
                                }}
                            />
                            <p>
                                Go to{" "}
                                <a href="http://downstreamimpact.com">
                                    downstreamimpact.com
                                </a>
                            </p>
                        </div>
                        <p>
                            For full access to our help center,{" "}
                            <Link to="/auth">
                                Log In to your Downstream account
                            </Link>
                        </p>
                    </footer>
                </>
            )}
        </Media>
    )
}

Main.propTypes = propTypes
Main.defaultProps = defaultProps

export default Main
