import React from "react"
import logoIcon from "./full-logo-color.svg"

const propTypes = {}
const defaultProps = {}

const LogoColor = () => <img alt="logo" src={logoIcon} />

LogoColor.defaultProps = defaultProps
LogoColor.propTypes = propTypes

export default LogoColor
