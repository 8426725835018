import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import ReactRouterPropTypes from "react-router-prop-types"
import { BackButton } from "components/BackButton"

import { isExactMatch } from "utilities/pages"

import styles from "./Breadcrumb.module.scss"

const propTypes = {
    small: PropTypes.bool,
    isDocked: PropTypes.bool,
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
}
const defaultProps = {
    small: false,
    isDocked: false,
}

const Breadcrumb = ({ small, isDocked, location: { pathname } }) => {
    let back = {
        to: "/",
        title: "Home",
    }

    if (isExactMatch(pathname, "/new-features/:id")) {
        back = {
            to: "/new-features",
            title: "New Features",
        }
    }

    const renderBreadcrumbLink = () => (
        <Link to={back.to} className="d-flex align-items-center">
            <BackButton />
            <span className={styles["back-text"]}>{back.title}</span>
        </Link>
    )

    return <div className={styles["back-link"]}>{renderBreadcrumbLink()}</div>
}

Breadcrumb.propTypes = propTypes
Breadcrumb.defaultProps = defaultProps

export default withRouter(Breadcrumb)
