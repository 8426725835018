import React from "react"

const ProfileIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse cx="10" cy="5.41667" rx="3.75" ry="4.16667" fill="white" />
            <path
                d="M17.5 15.8333C17.5 18.3646 13.6819 17.5 10 17.5C6.3181 17.5 2.5 18.3646 2.5 15.8333C2.5 13.302 6.3181 11.25 10 11.25C13.6819 11.25 17.5 13.302 17.5 15.8333Z"
                fill="white"
            />
        </svg>
    )
}

export default ProfileIcon
