import set from "lodash/fp/set"
import flow from "lodash/fp/flow"
import get from "lodash/get"

import { APP } from "actions/constants"

export default (state, action) => ({
    [APP.SET.LOADING]: set(["app", "loading"], true, state),
    [APP.SET.FINISHED_LOADING]: set(["app", "loading"], false, state),
    [APP.SET.RECENTLY_ADDED_FEATURES]: set(
        ["app", "recentlyAddedFeatures"],
        action.payload,
        state
    ),
    [APP.SET.SEO]: set(["app", "seo"], action.payload, state),
    [APP.SET.USER_LOADING]: set(
        ["app", "user", "loading"],
        action.payload,
        state
    ),
    [APP.SET.USER]: flow(
        set(["app", "user", "username"], get(action, ["payload", "username"])),
        set(["app", "user", "email"], get(action, ["payload", "email"])),
        set(["app", "user", "name"], get(action, ["payload", "name"]))
    )(state),
    [APP.SET.USER_ERROR]: set(["app", "user", "error"], action.payload, state),
})
