import React from "react"

import MenuItems from "./MenuItems"

import { colorBg } from "theme.scss"

const Menu = ({ title, items, open, closeMenu }) => {
    const menuOpacity = open ? 1 : 0
    const menuOffset = open ? 0 : 40

    return (
        <div
            style={{
                opacity: menuOpacity,
                transition: "opacity 0.5s ease",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "fixed",
                backgroundColor: colorBg,
                zIndex: 2,
                height: "100vh",
                overflowY: "auto",
                WebkitOverflowScrolling: "touch",
                pointerEvents: open ? "auto" : "none",
            }}
        >
            <div
                style={{
                    transform: `translate(0px, ${menuOffset}px)`,
                    transition: "transform 0.5s ease",
                    marginTop: 80,
                }}
            >
                <div
                    style={{
                        width: "100%",
                        paddingLeft: 20,
                        position: "relative",
                    }}
                >
                    <MenuItems
                        title={title}
                        items={items}
                        closeMenu={closeMenu}
                    />
                </div>
            </div>
        </div>
    )
}

export default Menu
