import get from "lodash/get"

const seoPath = ["fields", "seo", "fields"]

export const selectSEOImageURL = entry =>
    get(entry, [...seoPath, "image", "fields", "file", "url"])

export const selectSEOTitle = entry => get(entry, [...seoPath, "title"])

export const selectSEODescription = entry =>
    get(entry, [...seoPath, "description"])
