import React, { useContext, useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import ReactRouterPropTypes from "react-router-prop-types"
import { Badge, Icon, Drawer, Button, Dropdown, Menu } from "antd"
import { ProfileIcon } from "components/ProfileIcon"
import classNames from "classnames"

import { Store } from "store"
import { Logo } from "components/Logo"
import { resetRecentlyAddedFeaturesCount, signOut } from "actions"
import { isUserSignedIn } from "utilities/auth"
import { colorFontDark } from "theme.scss"
import { topNavigationDockTrigger } from "theme"

import styles from "./Header.module.scss"

const propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
}
const defaultProps = {}

const TopNavigation = ({ matches: { small }, location: { pathname } }) => {
    const { state, dispatch } = useContext(Store)
    const [yPosition, setYPosition] = useState(window.pageYOffset)

    useEffect(() => {
        const checkPosition = () => {
            setYPosition(window.pageYOffset)
        }
        window.addEventListener("scroll", checkPosition)
        return () => {
            window.removeEventListener("scroll", checkPosition)
        }
    }, [])

    const [isDrawerOpened, setIsDrawerOpened] = useState(false)

    const {
        app: { recentlyAddedFeatures, user },
    } = state

    const handleClickNewFeatures = () => {
        resetRecentlyAddedFeaturesCount(dispatch)
    }

    const openDrawer = () => setIsDrawerOpened(true)

    const closeDrawer = () => setIsDrawerOpened(false)

    const isDocked = () => yPosition >= topNavigationDockTrigger

    return (
        <header
            className={classNames(styles.nav, {
                [styles.docked]: isDocked(),
            })}
        >
            <div
                className={classNames(
                    "d-flex",
                    "align-items-center",
                    "container",
                    "justify-content-between"
                )}
            >
                <div>
                    <Link
                        to="/"
                        className={classNames(
                            "d-flex",
                            "align-items-center",
                            styles["logo-link"]
                        )}
                    >
                        <div className={styles["full-logo"]}>
                            <Logo
                                fills={{
                                    large: isDocked() ? "#292A33" : "white",
                                    small: isDocked() ? "#0097FF" : "white",
                                }}
                                size={small ? "sm" : "md"}
                            />
                            <span
                                className={classNames(
                                    "ml-2",
                                    styles["logo-text"],
                                    {
                                        [styles.docked]: isDocked(),
                                    }
                                )}
                                style={{ fontSize: small ? 14 : 15 }}
                            >
                                downstream
                            </span>
                        </div>
                        <span className={styles["logo-divider"]}></span>
                        <span className={styles["logo-title"]}>
                            Help Center
                        </span>
                    </Link>
                </div>
                {small ? (
                    <div className="d-flex align-items-center">
                        <div>
                            <Icon
                                type="menu"
                                style={{
                                    fontSize: 20,
                                    color: isDocked() ? colorFontDark : "white",
                                }}
                                onClick={openDrawer}
                            />
                            <Drawer
                                placement="right"
                                closable
                                onClose={closeDrawer}
                                visible={isDrawerOpened}
                                title="Menu"
                            >
                                <ul className={styles["mobile-nav-links"]}>
                                    <li>
                                        <Button
                                            type="link"
                                            className={
                                                styles["mobile-drawer-btn"]
                                            }
                                            onClick={closeDrawer}
                                        >
                                            <Link to="/">Help Center</Link>
                                        </Button>
                                    </li>
                                    <li>
                                        <Badge count={recentlyAddedFeatures} />
                                        <Button
                                            type="link"
                                            className={
                                                styles["mobile-drawer-btn"]
                                            }
                                            onClick={() => {
                                                closeDrawer()
                                                handleClickNewFeatures()
                                            }}
                                        >
                                            <Link to="/new-features">
                                                New Features
                                            </Link>
                                        </Button>
                                    </li>
                                    {isUserSignedIn(user) ? (
                                        <li>
                                            <Button
                                                type="link"
                                                className={
                                                    styles[
                                                        "mobile-drawer-btn"
                                                    ]
                                                }
                                                onClick={() =>
                                                    signOut(dispatch)
                                                }
                                            >
                                                Log Out
                                            </Button>
                                            <span
                                                className={
                                                    styles[
                                                        "mobile-drawer-divider"
                                                    ]
                                                }
                                            ></span>
                                            <p>{user.name}</p>
                                        </li>
                                    ) : (
                                        <>
                                            <li>
                                                <a href="https://forge.downstreamimpact.com/auth/signup">
                                                    Sign Up
                                                </a>
                                            </li>
                                            <li>
                                                <Button
                                                    type="link"
                                                    className={
                                                        styles["mobile-drawer-btn"]
                                                    }
                                                >
                                                    <Link to="/auth">Log In</Link>
                                                </Button>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </Drawer>
                        </div>
                    </div>
                ) : (
                    <>
                        <nav>
                            <ul className="d-flex align-items-center">
                                <li className={styles["new-features-link"]}>
                                    <Badge
                                        count={recentlyAddedFeatures}
                                    ></Badge>
                                    <Link to="/new-features">New Features</Link>
                                </li>
                                {!isUserSignedIn(user) && (
                                    <li>
                                        <a href="https://forge.downstreamimpact.com/auth/signup">
                                            Sign Up
                                        </a>
                                    </li>
                                )}
                            </ul>
                            <div className={styles["user-auth"]}>
                                {isUserSignedIn(user) ? (
                                    <Dropdown
                                        overlay={
                                            <Menu>
                                                <Menu.Item
                                                    onClick={() =>
                                                        signOut(dispatch)
                                                    }
                                                >
                                                    Log Out
                                                </Menu.Item>
                                            </Menu>
                                        }
                                        placement="bottomRight"
                                    >
                                        <div
                                            className={styles["logged-in-auth"]}
                                        >
                                            <ProfileIcon />
                                            <p>{user.name}</p>
                                        </div>
                                    </Dropdown>
                                ) : (
                                    <Button
                                        className={classNames(
                                            styles["nav-btn"],
                                            {
                                                [styles[
                                                    "nav-btn-docked"
                                                ]]: isDocked(),
                                            }
                                        )}
                                        type="link"
                                    >
                                        <Link to="/auth">Log In</Link>
                                    </Button>
                                )}
                            </div>
                        </nav>
                    </>
                )}
            </div>
        </header>
    )
}

TopNavigation.propTypes = propTypes
TopNavigation.defaultProps = defaultProps

export default withRouter(TopNavigation)
