import React from "react"
import PropTypes from "prop-types"

const propTypes = {
    className: PropTypes.string,
    fills: PropTypes.shape({
        small: PropTypes.string,
        large: PropTypes.string,
    }),
    size: PropTypes.oneOfType([
        PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
        PropTypes.number,
    ]),
}

const defaultProps = {
    className: "",
    fills: {
        large: "#292A33",
        small: "#0097FF",
    },
    size: "sm",
}

const Logo = ({ className, fills: { large, small }, size }) => {
    const getSize = () => {
        switch (size) {
            case "sm":
                return 26
            case "md":
                return 30
            default:
                return size
        }
    }

    return (
        <svg
            className={className}
            viewBox="0 0 48 50"
            xmlns="http://www.w3.org/2000/svg"
            height={getSize()}
            width={getSize()}
        >
            <path
                d="M37.593 30.22c.1-.235.197-.473.285-.714.594-1.62.971-3.557.971-5.375 0-7.618-6.048-13.995-13.508-13.995-6.398 0-12.042 4.69-13.522 10.83a16.823 16.823 0 0 1 5.127-1.738c-.102.114-.201.232-.301.348-.063.074-.127.148-.188.222a17.552 17.552 0 0 0-.463.587c-.092.122-.182.244-.27.368-.054.074-.106.15-.158.225-.091.132-.183.264-.272.4l-.003.006c-.218.334-.423.678-.619 1.028l-.094.167a17.5 17.5 0 0 0-.238.456l-.104.207c-.076.155-.15.312-.222.47l-.083.187c-.075.17-.148.34-.218.513l-.006.017c-.245.61-.456 1.234-.632 1.87l-.039.142c-.05.188-.098.377-.142.567l-.035.156c-.044.197-.086.394-.123.593l-.01.055c-.898 4.821.226 9.806 3.1 13.739a15.984 15.984 0 0 1-2.09-10.168l.028.045c.928-6.72 5.916-12.109 12.35-13.493-3.439 3.054-5.412 7.482-5.408 12.136 0 7.922 5.6 14.508 12.978 15.857a.216.216 0 0 1 .076.025 15.4 15.4 0 0 0 1.57.188A23.556 23.556 0 0 1 24.059 49C10.77 49 0 38 0 24.433 0 16 4.16 8.563 10.499 4.138c.239-.166.48-.329.725-.487l.023-.014c.485-.312.982-.473 1.49-.75l.044-.024c.5-.272 1.011-.526 1.532-.762l.073-.033c.253-.114.51-.223.767-.329l.01-.004c.26-.106.522-.207.787-.303l.105-.038c.254-.091.51-.18.77-.263l.02-.007c.264-.085.53-.164.798-.24l.137-.037c.255-.07.512-.137.77-.2l.033-.007c.267-.064.535-.121.805-.176l.166-.032c.258-.05.517-.097.778-.138l.037-.007A23.652 23.652 0 0 1 21.37.153c.265-.03.53-.057.797-.078l.029-.002a23.888 23.888 0 0 1 1.027-.057c.278-.01.556-.016.836-.016C37.346 0 47 11.066 47 24.634c0 .357-9.437 5.656-9.407 5.585z"
                fill={large}
            ></path>
            <path
                d="M40.129 42.302c-.846.579-2.858.726-3.864.694-4.106-.134-7.75-2.034-10.03-5.172.078-.002.157 0 .235-.004 5.567-.213 8.785-2.855 11.352-8.24.057-.118.116-.233.17-.356A19.973 19.973 0 0 0 39.66 21.2c0-10.828-8.554-19.607-19.105-19.607A18.637 18.637 0 0 0 11 4.223 23.131 23.131 0 0 1 24.337 0C37.406 0 48 10.873 48 24.285c0 7.183-2.879 14.6-7.871 18.017z"
                fill={small}
            ></path>
        </svg>
    )
}

Logo.defaultProps = defaultProps
Logo.propTypes = propTypes

export default Logo
