import set from "lodash/fp/set"

import { CATEGORIES } from "actions/constants"

export default (state, action) => ({
    [CATEGORIES.FETCH.CATEGORIES_LIST]: set(
        ["categories", "list"],
        action.payload,
        state
    ),
    [CATEGORIES.FETCH.CATEGORY]: set(
        ["categories", "current"],
        action.payload,
        state
    ),
})
