import Auth from "@aws-amplify/auth"

/**
 * Signs in a user with Cognito
 *
 * @param username the email address for the user
 * @param password the password for the user
 * @returns {Promise<any>}
 */
export const signInWithCognito = (username, password) =>
    Auth.signIn(username, password)

/**
 * Sign out a user with Cognito
 *
 * @returns {Promise<any>}
 */
export const signOutWithCognito = () => Auth.signOut()

/**
 * Get current user information for the authenticated user from Cognito
 *
 * @returns {Promise<any>}
 */
export const currentAuthenticatedUserWithCognito = async () => {
    let CognitoUser

    try {
        CognitoUser = await Auth.currentAuthenticatedUser()
    } catch (e) {
        // sign out will clear all existing cognito keys from localStorage
        await signOutWithCognito()
        CognitoUser = null
    }

    return CognitoUser
}
