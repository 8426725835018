import React, { useState } from "react"
import PropTypes from "prop-types"
import ScrollLock from "react-scrolllock"

import Menu from "./Menu"
import MenuButton from "./MenuButton"

const propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
            active: PropTypes.bool,
        })
    ),
}
const defaultProps = {
    title: "",
    items: [],
}

const MobilePageNavigation = ({ title, items }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const handleButtonClick = () => setIsMenuOpen(!isMenuOpen)
    const closeMenu = () => setIsMenuOpen(false)

    return (
        <>
            <ScrollLock isActive={isMenuOpen} />
            <Menu
                title={title}
                items={items}
                open={isMenuOpen}
                closeMenu={closeMenu}
            />
            <MenuButton open={isMenuOpen} onButtonClick={handleButtonClick} />
        </>
    )
}

MobilePageNavigation.propTypes = propTypes
MobilePageNavigation.defaultProps = defaultProps

export default MobilePageNavigation
