import React, { useEffect, useContext } from "react"
import { Switch, Route } from "react-router-dom"
import { withRouter } from "react-router-dom"
import flowRight from "lodash/fp/flowRight"
import get from "lodash/get"

import { Store } from "store"
import {
    initializeRecentlyAddedFeaturesCount,
    fetchDefaultSEO,
    initializeUserSession,
} from "actions"

import { Category } from "views/Category"
import { NewFeatures } from "views/NewFeatures"
import { NewFeature } from "views/NewFeature"
import { Home } from "views/Home"
import { Authentication } from "views/Authentication"

import { withGoogleTrackerFactory } from "components/HOC/withGoogleTrackerFactory"
import { SEO } from "components/SEO"

const propTypes = {}
const defaultProps = {}

const App = () => {
    const { state, dispatch } = useContext(Store)

    const user = get(state, ["app", "user"])

    useEffect(() => {
        initializeRecentlyAddedFeaturesCount(user, dispatch)
    }, [user, dispatch])

    useEffect(() => {
        fetchDefaultSEO(dispatch)
    }, [dispatch])

    useEffect(() => {
        initializeUserSession(dispatch)
    }, [dispatch])

    return (
        <>
            <SEO />
            <Switch>
                <Route exact path={"/categories/:id"} component={Category} />
                <Route exact path={"/new-features"} component={NewFeatures} />
                <Route
                    exact
                    path={"/new-features/:id"}
                    component={NewFeature}
                />
                <Route exact path={"/auth"} component={Authentication} />
                <Route render={Home} />
            </Switch>
        </>
    )
}

App.propTypes = propTypes
App.defaultProps = defaultProps

export default flowRight(
    withRouter,
    withGoogleTrackerFactory()
)(App)
