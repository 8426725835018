import React from "react"
import { withRouter, Link } from "react-router-dom"
import Media from "react-media"
import get from "lodash/get"
import { Button, Skeleton } from "antd"
import classNames from "classnames"
import ReactRouterPropTypes from "react-router-prop-types"
import { Helmet } from "react-helmet"

import { Main } from "components/Main"
import { fetchCategories } from "actions"
import { Store } from "store"
import { mediaQueries } from "theme"

import styles from "./Home.module.scss"

const propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
}
const defaultProps = {}

const Home = ({ history, match }) => {
    const { state, dispatch } = React.useContext(Store)

    const user = get(state, ["app", "user"])

    React.useEffect(() => {
        fetchCategories(user, dispatch)
    }, [dispatch, user])

    const loading = get(state, ["app", "loading"])
    if (loading) {
        return <Skeleton active />
    }

    const categoryList = get(state, ["categories", "list"])

    const generateTopicsList = (categoryPath, topicList) =>
        topicList && (
            <ul className="nav flex-column">
                {topicList.map(({ fields }, idx) =>
                    fields ? (
                        <li key={idx} className="nav-item">
                            <Link
                                className={classNames([
                                    "nav-link",
                                    styles["topic-link"],
                                ])}
                                to={{
                                    pathname: `/categories/${categoryPath}`,
                                    hash: `#${fields.path}`,
                                }}
                            >
                                {fields.name}
                            </Link>
                        </li>
                    ) : null
                )}
            </ul>
        )

    const generateCategoryCards = ({ small }) =>
        categoryList
            .sort((a, b) => {
                const nameA = get(a, ["fields", "name"])
                const nameB = get(b, ["fields", "name"])
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
            })
            .map(({ fields }, idx) => {
                return fields ? (
                    <li key={idx} className={styles["category-card"]}>
                        <div>
                            <h3>{fields.name}</h3>
                            {generateTopicsList(fields.path, fields.topics)}
                        </div>

                        <Button
                            className="mt-4"
                            type="primary"
                            size="large"
                            onClick={() =>
                                history.push(`/categories/${fields.path}`)
                            }
                        >
                            View All
                        </Button>
                    </li>
                ) : null
            })

    return (
        <>
            <Helmet>
                <body className={styles.homepage} />
            </Helmet>
            <Main isNotFound={!match.isExact}>
                <ul className={styles["category-list"]}>
                    <Media queries={mediaQueries}>
                        {matches => generateCategoryCards(matches)}
                    </Media>
                </ul>
            </Main>
        </>
    )
}

Home.propTypes = propTypes
Home.defaultProps = defaultProps

export default withRouter(Home)
