import React from "react"
import ReactDOM from "react-dom"
import { Router } from "react-router-dom"
import history from "utilities/history"
import ReactGA from "react-ga"
import Auth from "@aws-amplify/auth"

// import project styles before application styles from <App />
import "./index.scss"

import { App } from "views/App"
import { StoreProvider } from "store"
import * as serviceWorker from "serviceWorker"

// configure google analytics
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
    titleCase: false,
})

// configure aws amplify sdk
Auth.configure({
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    mandatorySignIn: false, // Enforce user authentication prior to accessing AWS resources or not
})

ReactDOM.render(
    <StoreProvider>
        <Router history={history}>
            <App />
        </Router>
    </StoreProvider>,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
