import { matchPath } from "react-router-dom"

import {
    getSEOEntry,
    getCategories,
    getNewFeatures,
    getNewFeature,
    getCategory,
    getRecentlyAddedFeatureCount,
} from "services/contentful"
import {
    signInWithCognito,
    signOutWithCognito,
    currentAuthenticatedUserWithCognito,
} from "services/cognito"
import { getCurrentPathname } from "utilities/pages"
import { isUserSignedIn } from "utilities/auth"
import { defaultSEOEntryId } from "theme"

import { APP, CATEGORIES, NEW_FEATURES } from "./constants"

export const fetchDefaultSEO = async dispatch => {
    const data = await getSEOEntry(defaultSEOEntryId)
    dispatch({
        type: APP.SET.SEO,
        payload: data,
    })
}

export const fetchCategory = async (categoryId, user, dispatch) => {
    dispatch({ type: APP.SET.LOADING })
    const data = await getCategory(categoryId, isUserSignedIn(user))
    dispatch({
        type: CATEGORIES.FETCH.CATEGORY,
        payload: data,
    })
    dispatch({ type: APP.SET.FINISHED_LOADING })
}

export const fetchCategories = async (user, dispatch) => {
    dispatch({ type: APP.SET.LOADING })
    const data = await getCategories(isUserSignedIn(user))
    dispatch({
        type: CATEGORIES.FETCH.CATEGORIES_LIST,
        payload: data,
    })
    dispatch({ type: APP.SET.FINISHED_LOADING })
}

export const fetchNewFeature = async (featureId, user, dispatch) => {
    dispatch({ type: APP.SET.LOADING })
    const data = await getNewFeature(featureId, isUserSignedIn(user))
    dispatch({
        type: NEW_FEATURES.FETCH.NEW_FEATURE,
        payload: data,
    })
    dispatch({ type: APP.SET.FINISHED_LOADING })
}

export const fetchNewFeatures = async (user, dispatch) => {
    dispatch({ type: APP.SET.LOADING })
    const data = await getNewFeatures({}, isUserSignedIn(user))
    dispatch({
        type: NEW_FEATURES.FETCH.NEW_FEATURES_LIST,
        payload: data,
    })
    dispatch({ type: APP.SET.FINISHED_LOADING })
}

export const loadMoreNewFeatures = async (
    currentListLength,
    user,
    dispatch
) => {
    dispatch({ type: NEW_FEATURES.SET.LOADING })
    const data = await getNewFeatures(
        { skip: currentListLength },
        isUserSignedIn(user)
    )
    dispatch({
        type: NEW_FEATURES.LOAD.NEW_FEATURES_LIST,
        payload: data,
    })
}

export const resetRecentlyAddedFeaturesCount = dispatch => {
    dispatch({
        type: APP.SET.RECENTLY_ADDED_FEATURES,
        payload: 0,
    })
}

export const initializeRecentlyAddedFeaturesCount = async (user, dispatch) => {
    const isNewFeaturesRoute = matchPath(getCurrentPathname(), {
        path: "/new-features",
    })

    // don't get new features for badge when user enters app on new features page
    if (!isNewFeaturesRoute) {
        const count = await getRecentlyAddedFeatureCount(isUserSignedIn(user))
        dispatch({
            type: APP.SET.RECENTLY_ADDED_FEATURES,
            payload: count,
        })
    }
}

export const signIn = async (
    { email: providedEmail, password: providedPassword },
    dispatch
) => {
    dispatch({
        type: APP.SET.USER_ERROR,
        payload: null,
    })
    dispatch({
        type: APP.SET.USER_LOADING,
        payload: true,
    })
    try {
        const CognitoUser = await signInWithCognito(
            providedEmail,
            providedPassword
        )

        const {
            attributes: { name, email },
        } = CognitoUser

        dispatch({
            type: APP.SET.USER,
            payload: {
                username: CognitoUser.getUsername(),
                email,
                name,
            },
        })
    } catch (e) {
        dispatch({
            type: APP.SET.USER_ERROR,
            payload: e.message,
        })
    }
    dispatch({
        type: APP.SET.USER_LOADING,
        payload: false,
    })
}

export const signOut = async dispatch => {
    dispatch({
        type: APP.SET.USER_ERROR,
        payload: null,
    })
    dispatch({
        type: APP.SET.USER_LOADING,
        payload: true,
    })
    try {
        await signOutWithCognito()
        dispatch({
            type: APP.SET.USER,
            payload: {
                username: null,
                email: null,
                name: null,
            },
        })
    } catch (e) {
        dispatch({
            type: APP.SET.USER_ERROR,
            payload: e.message,
        })
    }
    dispatch({
        type: APP.SET.USER_LOADING,
        payload: false,
    })
}

export const initializeUserSession = async dispatch => {
    const CognitoUser = await currentAuthenticatedUserWithCognito()

    if (CognitoUser) {
        const {
            attributes: { name, email },
        } = CognitoUser

        dispatch({
            type: APP.SET.USER,
            payload: {
                username: CognitoUser.getUsername(),
                email,
                name,
            },
        })
    }
}
