import React, { useEffect } from "react"
import ReactGA from "react-ga"

/**
 * Records React Router changes to Google Analytics
 *
 * @see https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 *
 * @param options
 * @returns {function(*): *}
 */
export const withGoogleTrackerFactory = (options = {}) => WrappedComponent => {
    const trackPage = page => {
        ReactGA.set({
            page,
            ...options,
        })
        ReactGA.pageview(page)
    }

    return props => {
        useEffect(() => trackPage(props.location.pathname), [
            props.location.pathname,
        ])

        return <WrappedComponent {...props} />
    }
}
