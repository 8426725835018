import React from "react"
import Media from "react-media"
import { Card } from "antd"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import get from "lodash/get"

import { mediaQueries } from "theme"

const richTextOptions = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: node => {
            const path = ["data", "target", "fields"]
            const { description, file, title } = get(node, path, {})
            const mimeType = get(file, "contentType")
            const { width } = get(file, ["details", "image"])
            const mimeGroup = mimeType && mimeType.split("/")[0]

            if (mimeGroup === "image") {
                return (
                    <Media queries={mediaQueries}>
                        {({ small }) => (
                            <Card
                                hoverable
                                style={{
                                    margin: "25px auto",
                                    maxWidth: width + 2, // accounts for 1px border on each side
                                }}
                                cover={
                                    <img
                                        title={title}
                                        alt={description}
                                        src={file.url}
                                    />
                                }
                                onClick={
                                    small
                                        ? undefined
                                        : () => window.open(file.url)
                                }
                                bodyStyle={{
                                    display: "none",
                                }}
                            />
                        )}
                    </Media>
                )
            }
        },
    },
}

export const bodyToReactComponents = data =>
    documentToReactComponents(data, richTextOptions)
