import React from "react"
import { List } from "antd"
import { Link } from "react-router-dom"

import { formatDate } from "utilities/formatters"

import LoadMoreButton from "./LoadMoreButton"
import styles from "./FeatureList.module.scss"

const FeatureList = ({ loading, loadMoreItems, totalItems, items }) => (
    <List
        className={styles["load-more-list"]}
        loading={loading}
        itemLayout="vertical"
        loadMore={
            <LoadMoreButton
                onClick={loadMoreItems}
                visible={totalItems > items.length}
            />
        }
        dataSource={items}
        renderItem={({ name, releaseDate, path }) => (
            <List.Item>
                <List.Item.Meta
                    title={<Link to={`new-features/${path}`}>{name}</Link>}
                    description={`${formatDate(releaseDate)}`}
                />
            </List.Item>
        )}
    />
)

export default FeatureList
