import React, { useEffect, useState } from "react"

import { topNavigationDockTrigger } from "theme"

import ChevronSvg from "./ChevronSvg"

const MenuButton = ({ open, onButtonClick }) => {
    const iconOffset = open ? 8 : -4

    const [yPosition, setYPosition] = useState(window.pageYOffset)
    useEffect(() => {
        const checkPosition = () => {
            setYPosition(window.pageYOffset)
        }
        window.addEventListener("scroll", checkPosition)
        return () => {
            window.removeEventListener("scroll", checkPosition)
        }
    }, [])

    const showButton = () => yPosition >= topNavigationDockTrigger

    return (
        showButton() && (
            <div
                style={{
                    backgroundColor: "#fff",
                    bottom: 44,
                    display: "inline-block",
                    cursor: "pointer",
                    position: "fixed",
                    right: 20,
                    zIndex: 3,
                    borderRadius: "50%",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    boxShadow: "0 0 20px rgba(0, 0, 0, 0.3)",
                    outline: "none",
                }}
                onClick={onButtonClick}
                role="button"
                tabIndex={0}
            >
                <div
                    style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            height: 60,
                            overflow: "hidden",
                            alignItems: "flex-start",
                        }}
                    >
                        <div
                            style={{
                                width: 20,
                                height: 20,
                                alignSelf: "center",
                                display: "flex",
                                flexDirection: "column",
                                color: "#6174F6",
                            }}
                        >
                            <ChevronSvg
                                cssProps={{
                                    transform: `translate(2px, ${iconOffset}px) rotate(180deg)`,
                                    transition: "transform 0.2s ease",
                                }}
                            />
                            <ChevronSvg
                                cssProps={{
                                    transform: `translate(2px, ${0 -
                                        iconOffset}px)`,
                                    transition: "transform 0.2s ease",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default MenuButton
