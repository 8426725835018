export const formatDate = inputString => {
    if (inputString) {
        const [year, month, day] = inputString.split("-")
        if (year && month && day) {
            return `${month}/${day}/${year}`
        }
        return inputString
    }
    return null
}
