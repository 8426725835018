import { matchPath } from "react-router-dom"
import history from "utilities/history"

export const getCurrentPathname = () => {
    const {
        location: { pathname },
    } = history

    return pathname
}

export const isExactMatch = (currentPath, path) =>
    matchPath(currentPath, { path, exact: true })

export const getPublicURL = pathname => {
    const { href } = new URL(pathname, process.env.REACT_APP_PUBLIC_URL)
    return href
}
