import React from "react"
import { Button } from "antd"

import styles from "./FeatureList.module.scss"

const LoadMoreButton = ({ onClick, visible = true }) =>
    visible && (
        <div className={styles["load-more-btn"]}>
            <Button onClick={onClick}>Load More</Button>
        </div>
    )

export default LoadMoreButton
