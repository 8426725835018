import React from "react"
import { Button } from "antd"

const colors = {
    brand: "#61dafb",
}

const MenuItems = ({ title, items, closeMenu }) => {
    return (
        <div>
            <button
                style={{
                    cursor: "pointer",
                    backgroundColor: "transparent",
                    border: 0,
                    padding: 0,
                    margin: "0 0 10px 5px",
                }}
            >
                <div style={{ fontSize: 18, fontWeight: 700 }}>{title}</div>
            </button>
            <ul
                style={{
                    margin: 0,
                    padding: 0,
                    display: "block",
                    listStyleType: "none",
                }}
            >
                {items.map(({ title, href, active }, idx) => (
                    <li style={{ margin: "0 0 0 5px" }} key={idx}>
                        {active && (
                            <span
                                style={{
                                    width: 4,
                                    height: 25,
                                    borderLeft: `4px solid ${colors.brand}`,
                                    paddingLeft: 16,
                                    position: "absolute",
                                    left: 0,
                                    marginTop: -3,
                                }}
                            />
                        )}
                        <Button
                            type="link"
                            href={href}
                            onClick={closeMenu}
                            style={{ padding: 0 }}
                        >
                            {title}
                        </Button>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default MenuItems
