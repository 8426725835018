import React from "react"

const BackButton = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.06573 17.0657C8.75331 17.3781 8.24678 17.3781 7.93436 17.0657L3.43436 12.5657C3.12194 12.2533 3.12194 11.7467 3.43436 11.4343L7.93436 6.9343C8.24678 6.62188 8.75331 6.62188 9.06573 6.9343C9.37815 7.24672 9.37815 7.75325 9.06573 8.06567L5.93142 11.2L20 11.2C20.4419 11.2 20.8 11.5582 20.8 12C20.8 12.4418 20.4419 12.8 20 12.8L5.93142 12.8L9.06573 15.9343C9.37815 16.2467 9.37815 16.7533 9.06573 17.0657Z"
                fill="#594DFF"
            />
        </svg>
    )
}

export default BackButton
