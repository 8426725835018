export const CATEGORIES = {
    FETCH: {
        CATEGORIES_LIST: "FETCH_CATEGORIES_LIST",
        CATEGORIES_LIST_ERROR: "FETCH_CATEGORIES_LIST_ERROR",
        CATEGORY: "FETCH_CATEGORY",
        CATEGORY_ERROR: "FETCH_CATEGORY_ERROR",
    },
}

export const NEW_FEATURES = {
    SET: {
        LOADING: "SET_NEW_FEATURES_LOADING",
    },
    FETCH: {
        NEW_FEATURES_LIST: "FETCH_NEW_FEATURES_LIST",
        NEW_FEATURES_LIST_ERROR: "FETCH_NEW_FEATURES_LIST_ERROR",
        NEW_FEATURE: "FETCH_NEW_FEATURE",
        NEW_FEATURE_ERROR: "FETCH_NEW_FEATURE_ERROR",
    },
    LOAD: {
        NEW_FEATURES_LIST: "LOAD_NEW_FEATURES_LIST",
        NEW_FEATURES_LIST_ERROR: "LOAD_NEW_FEATURES_LIST_ERROR",
    },
}

export const APP = {
    SET: {
        LOADING: "SET_APP_LOADING",
        FINISHED_LOADING: "SET_APP_FINISHED_LOADING",
        RECENTLY_ADDED_FEATURES: "SET_APP_RECENTLY_ADDED_FEATURES",
        SEO: "SET_APP_SEO",
        USER: "SET_APP_USER",
        USER_LOADING: "SET_APP_USER_LOADING",
        USER_ERROR: "SET_APP_USER_ERROR",
    },
}
