import React, { useContext } from "react"
import get from "lodash/get"
import { Skeleton } from "antd"
import { Helmet } from "react-helmet"

import { Store } from "store"
import { loadMoreNewFeatures, fetchNewFeatures } from "actions"
import { Main } from "components/Main"

import { FeatureList } from "./FeatureList"

import styles from "./NewFeatures.module.scss"

const NewFeatures = () => {
    const { state, dispatch } = useContext(Store)

    const user = get(state, ["app", "user"])

    React.useEffect(() => {
        fetchNewFeatures(user, dispatch)
    }, [dispatch, user])

    const loading = get(state, ["app", "loading"])
    if (loading) {
        return <Skeleton active />
    }

    const newFeatures = get(state, ["newFeatures", "list"])
    const loadingMoreFeatures = get(state, ["newFeatures", "loading"])
    const total = get(state, ["newFeatures", "total"])

    const handleLoadMore = () => {
        loadMoreNewFeatures(newFeatures.length, user, dispatch)
    }

    return (
        <>
            <Helmet>
                <body className="new-features-page" />
            </Helmet>
            <Main title="New Features from Downstream">
                <article className={styles["features-home-content"]}>
                    <FeatureList
                        items={newFeatures.map(item => ({
                            path: get(item, ["fields", "path"]),
                            name: get(item, ["fields", "name"]),
                            releaseDate: get(item, ["fields", "releaseDate"]),
                        }))}
                        totalItems={total}
                        loading={loadingMoreFeatures}
                        loadMoreItems={handleLoadMore}
                    />
                </article>
            </Main>
        </>
    )
}

export default NewFeatures
