import React from "react"
import Media from "react-media"
import classNames from "classnames"
import PropTypes from "prop-types"

import { mediaQueries } from "theme"

import styles from "./Header.module.scss"
import TopNavigation from "./TopNavigation"

const propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
}
const defaultProps = {
    subTitle: null,
}

const Header = ({ title, subTitle }) => {
    const titleClass = ({ small }) => (small ? "display-5" : "display-4")

    return (
        <Media queries={mediaQueries}>
            {matches => (
                <>
                    <TopNavigation matches={matches} />

                    <div
                        className={classNames(
                            "jumbotron",
                            "jumbotron-fluid",
                            styles.header,
                            {
                                [styles.small]: matches.small,
                            }
                        )}
                    >
                        <div className={styles["floating-elements"]}>
                            <div
                                className={classNames(
                                    styles["dot-grid"],
                                    styles.right
                                )}
                            />
                            <div
                                className={classNames(
                                    styles["dot-grid"],
                                    styles.left
                                )}
                            />
                            <div className={styles.circle}>
                                <div className={styles.shape} />
                            </div>
                        </div>

                        <div className="container pb-4">
                            <div className="container-fluid text-center">
                                <h1
                                    className={classNames(
                                        titleClass(matches),
                                        styles.title
                                    )}
                                >
                                    {title}
                                </h1>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Media>
    )
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default Header
