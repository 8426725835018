import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import { Alert, Button, Col, Form, Input, Row } from "antd"
import update from "lodash/fp/update"

import { Store } from "store"
import { signIn } from "actions"

import styles from "./Authentication.module.scss"

const propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func,
        validateFields: PropTypes.func,
    }).isRequired,
}

function LoginForm({ form }) {
    const { state, dispatch } = React.useContext(Store)

    const user = get(state, ["app", "user"])
    const { loading, error } = user

    function handleSubmit(e) {
        e.preventDefault()
        form.validateFields((err, values) => {
            if (!err) {
                signIn(
                    update("email", email => email.toLowerCase())(values),
                    dispatch
                )
            }
        })
    }

    function emailFieldDecorator(component) {
        return form.getFieldDecorator("email", {
            rules: [
                {
                    required: true,
                    message: "Email is required.",
                },
                {
                    type: "email",
                    message: "The input is not valid email.",
                },
            ],
            validateTrigger: "",
        })(component)
    }

    function passwordFieldDecorator(component) {
        return form.getFieldDecorator("password", {
            rules: [
                {
                    required: true,
                    message: "Password is required.",
                },
            ],
            validateTrigger: "",
        })(component)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Row className={styles.title}>
                <Col>
                    <h1>Sign in</h1>
                </Col>
            </Row>

            <Form.Item>
                {emailFieldDecorator(<Input placeholder="Email" />)}
            </Form.Item>

            <Form.Item>
                {passwordFieldDecorator(
                    <Input type="password" placeholder="Password" />
                )}
            </Form.Item>

            <Row type="flex" justify="space-between" align="middle">
                <Col span={10}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        className={styles["sign-in-btn"]}
                    >
                        Sign in
                    </Button>
                </Col>
                <Col span={10} style={{ textAlign: "right" }}>
                    <a
                        href={process.env.REACT_APP_FORGE_FORGOT_PASSWORD_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Forgot password?
                    </a>
                </Col>
            </Row>

            <Row style={{ marginTop: "26px" }}>
                <Col>
                    <div className={styles["sign-up"]}>
                        Don&apos;t have a Downstream account?
                    </div>
                    <a
                        href={process.env.REACT_APP_FORGE_SIGNUP_PASSWORD_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Sign up now
                    </a>
                </Col>
            </Row>

            {error && (
                <Row className={styles["row-spacing"]}>
                    <Col>
                        <Alert message={error} type="error" />
                    </Col>
                </Row>
            )}
        </Form>
    )
}

LoginForm.propTypes = propTypes

export default Form.create()(LoginForm)
