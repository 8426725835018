import set from "lodash/fp/set"
import get from "lodash/get"
import flow from "lodash/fp/flow"

import { NEW_FEATURES } from "actions/constants"

export default (state, action) => ({
    [NEW_FEATURES.FETCH.NEW_FEATURE]: set(
        ["newFeatures", "current"],
        action.payload,
        state
    ),
    [NEW_FEATURES.FETCH.NEW_FEATURES_LIST]: flow(
        set(["newFeatures", "list"], get(action, ["payload", "data"])),
        set(["newFeatures", "total"], get(action, ["payload", "total"])),
        set(["newFeatures", "loading"], false)
    )(state),
    [NEW_FEATURES.LOAD.NEW_FEATURES_LIST]: flow(
        set(
            ["newFeatures", "list"],
            [...state.newFeatures.list, ...get(action, ["payload", "data"], [])]
        ),
        set(["newFeatures", "loading"], false)
    )(state),
    [NEW_FEATURES.SET.LOADING]: set(["newFeatures", "loading"], true, state),
})
